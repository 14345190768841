// NOTE: 修改这个文件的某个值，可能需要修改该值对应的页面的 scss

enum Pages {
  /* Mobile */
  PrivacyPolicy = '/privacyPolicy',
  TermsOfService = '/termsOfService',
  PrivacyPolicyUs = '/privacyPolicyUs',
  TermsOfServiceUs = '/termsOfServiceUs',
  PrivacyPolicyNz = '/privacyPolicyNz',
  TermsOfServiceNz = '/termsOfServiceNz',
  PrivacyPolicyCa = '/privacyPolicyCa',
  TermsOfServiceCa = '/termsOfServiceCa',
  PrivacyPolicyEweNz = '/privacyPolicyEweNz',
  TermsOfServiceEweNz = '/termsOfServiceEweNz',
  PrivacyPolicyEweAu = '/privacyPolicyEweAu',
  TermsOfServiceEweAu = '/termsOfServiceEweAu',
  Tracking = '/t',
  Faq = '/faq',
  Search = '/search',
  Invoice = '/invoice',
  Authorize = '/authorize',

  Login = '/f/login',
  Details = '/f/details',
  DriverData = '/f/driver-data',

  UnsettlementData = '/f/unsettlementData',
  ForgotPassword = '/f/forgotPassword',
  HistoricalBill = '/f/historicalBill',
  Service = '/service',
  /* PC */
  PCHome = '/',
  PCSearch = '/pc/search',
  PCFaq = '/pc/faq',
  PCService = '/pc/service',
}

export default Pages;
