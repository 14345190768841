import type { MenuProps } from 'antd';
import React from 'react';
import { Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { getLocale, s } from '@/locales';

import './index.scss';

const urlMap: Record<string, string> = {
  'au': 'https://au.fh.express/',
  'nz': 'https://nz.fh.express/',
  'ewe-nz': 'https://ewe-nz.homexpress.com.au/',
  'ewe-au': 'https://ewe-au.homexpress.com.au/',
};

const menuData: MenuProps['items'] = [
  {
    label: <span className="country-title">{s('国家或地区')}</span>,
    key: 'select',
    disabled: true,
  },
  {
    key: 'au',
    label: s('澳大利亚'),
  },
  {
    key: 'nz',
    label: s('新西兰'),
  },
];

const CountryDropdown: React.FC = () => {
  const regionName = window.__multRegion.replace('ewe-', '');
  const onClick: MenuProps['onClick'] = (info) => {
    if (info.key === regionName) {
      return;
    }
    const urlKey = window.__multRegion.startsWith('ewe-') ? `ewe-${info.key}` : info.key;
    const url = `${urlMap[urlKey]}?locale=${getLocale()}`;
    window.open(url);
  };

  const { label: countryName } = menuData.find((item) => item?.key === regionName) || {
    label: s('国家或地区'),
  };

  return (
    <Dropdown
      overlayClassName="region-dropdown"
      menu={{ items: menuData, selectedKeys: [regionName], onClick }}
    >
      <Space className="region-label">
        <span>{countryName}</span>
        <DownOutlined className="region-icon" rev={undefined} />
      </Space>
    </Dropdown>
  );
};

export default CountryDropdown;
export { urlMap };
