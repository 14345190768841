export default {
  '订单查询': 'TRACKING',
  '帮助？': 'Need Help?',
  '寄件地': 'From',
  '预计送达': 'Estimated Delivery',
  '快递单号': 'Parcel No.',
  '您的包裹': 'Your parcel',
  '完整路径': 'Tracking status',
  '更多': 'See more',
  '查询其他包裹': 'Track another parcel',
  '已送达': 'Delivered',
  '${realReceiverName} 已签收': 'Received',
  '配送评价': 'How was your delivery?',
  '允许我们与您取得联系？': 'Allow us to contact you',
  '同意': 'Yes',
  '不同意': 'No',
  '备注': 'Remarks',
  '添加备注': 'Add remarks',
  '修改备注': 'Modify remarks',
  '如果包裹当前正在配送中，司机可能看不到，暂不支持通过备注修改地址': 'If the parcel is currently being delivered, the driver may not see it. Therefore, the address cannot be modified by remarks',
  '如果您有需要提醒司机的事宜,可以通过备注告诉他': 'If you need to remind the driver of something, you can tell him through the remarks',
  '确定': 'OK',
  '取消': 'Cancel',
  '系统显示我的包裹“已丢失”，我应该怎么办？': 'My parcel has been declared "Lost" according to the system. What should I do?',
  '很抱歉，我们会尽快联系卖家进行赔偿。请联络卖家处理。': 'Sorry, we will contact the seller soon for compensation. Please contact the seller for further assistance.',
  '系统显示我的包裹“已签收”，但是我没找到，我应该怎么办？': 'My parcel has been "Delivered" according to the system, but I can\'t find it. What should I do?',
  '请参考签收图片，并询问邻居或大厦管理员。如果仍未找到，建议联络警方处理。': 'Please refer to the photo of delivery. We kindly suggest inquiring with your neighbors or the building\'s security personnel to double-check. If necessary, please contact the police for help.',
  '系统显示我的包裹“已签收”，但是签收图片不是我家，我应该怎么办？': 'My parcel has been "Delivered" according to the system, but the photo shows the wrong place. What should I do?',
  '很抱歉，请将Tracking no、系统签收时间、正确地址信息（附正确地址照片）、包裹价值（附订单截图）电邮至我们的邮箱customer_service@fasthorse.com.au，以便我们进一步跟进。': 'We are sorry for that. Please email the tracking no, delivery time, correct address information (with a photo of the correct address), and the package value (with a screenshot of the order) to our email customer_service@fasthorse.com.au, so that we can further follow up.',
  '我买的东西少件了/这不是我买的东西，我应该怎么办？': 'There is missing item in my parcel/The item is wrong in my parcel. What should I do?',
  '很抱歉，FHE专注尾程配送，我们只负责送达包裹。包裹内容物有问题请及时联系商家处理。': 'Sorry, FHE focuses on last-mile delivery. We are only responsible for delivering parcels. If there is any problem with the contents of the parcel, please contact the sender for further assistance.',
  '我的包裹破损了，内容物已经损坏，我应该怎么办？': 'My parcel is broken and the item is damaged. What should I do?',
  '请参考签收图片。若签收时外包装破损，请将Tracking no、系统签收时间、包裹照片、包裹价值（附订单截图）、其他有用资料（如有）电邮至我们的邮箱customer_service@fasthorse.com.au，以便我们进一步跟进。': 'Please refer to the photo of delivery. If the parcel has already broken before you receive it, please email the tracking no, delivery time, the photo of delivery, the package value (with a screenshot of the order), and any other supporting documents to our email customer_service@fasthorse.com.au, so that we can further follow up.',
  '系统显示我的包裹“已为您创建订单”，我何时才能收到？': 'My parcel is "Order created". When will I receive it?',
  '请耐心等待，包裹尚未到达仓库，入库后我们将尽快安排派送。': 'Please wait patiently, the package has not yet arrived at the warehouse. We will arrange delivery as soon as possible after arriving at the warehouse.',
  '系统显示我的包裹“待配送”，我何时才能收到？': 'My parcel is "Arrived at Warehouse". When will I receive it?',
  '请耐心等待，我们将尽快安排派送。': 'Please wait patiently, we will arrange delivery as soon as possible.',
  '系统显示我的包裹“派送中”，我何时才能收到？': 'My parcel is "Delivering". When will I receive it?',
  '请耐心等待，通常情况下司机将于24小时内送达，实际送达时间视情况而定。': 'Please wait patiently. Usually the driver will deliver within 24 hours. The actual delivery time depends on the situation.',
  '系统显示我的包裹“派送失败返仓中”，我何时才能收到？': 'My parcel is "Undelivered". When will I receive it?',
  '请耐心等待，我们会尽快安排第二次派送，或将包裹退回至卖家。请关注物流信息。': 'Please wait patiently. We will arrange a second delivery as soon as possible, or return the package to the seller. Please pay attention to the logistics information.',
  '系统显示我的包裹“已退运”，我何时才能收到？': 'My parcel is "Retun to the Sender". When will I receive it?',
  '很抱歉，我们已将包裹退回卖家，请联络卖家处理。': 'Sorry, we have returned the package to the seller. Please contact the seller to deal with it.',
  '系统显示我的包裹“已转运”，我何时才能收到？': 'My parcel has been "Forwarded to Australia Post". When will I receive it?',
  '请耐心等待，我们已将包裹转至其他公司配送。您仍可在我们的网站追踪物流。': 'Please wait patiently.',
  '为什么会显示“导航/地址错误”？': 'Why does it indicate my address is insufficient?',
  '很抱歉，可能是由于系统导航无法正确定位地址，我们将尽快安排转至其他公司配送或退回卖家。': 'Sorry, it may be due to the system navigation cannot locate the address correctly. We will arrange to transfer to another courier for re-delivery or return it to the seller as soon as possible.',
  '我是否能到仓库自提？': 'Can I pick up my parcel at your warehouse?',
  '抱歉，现时不支持仓库自取。请耐心等待，我们将尽快将包裹送达。': 'Sorry, warehouse self-pickup is not supported currently. Please be patient, we will deliver the package as soon as possible.',
  '我是否可以选择送达时间？': 'Can I choose the delivery time?',
  '抱歉，现时不支持选择送达时间。请耐心等待，我们将尽快将包裹送达。': 'Sorry, choosing a delivery time is not supported currently. Please be patient, we will deliver the package as soon as possible.',
  '我是否可以修改收货地址？': 'Can I change my shipping address?',
  '抱歉，现时不支持修改送货地址。如有相关需求请联系卖家处理。': 'Sorry, amending the shipping address is not supported currently. If you have any related needs, please contact the seller to deal with it.',
  '我是否可以要求司机送至指定位置？': 'Can I ask the driver to place the parcel at a designated location?',
  '抱歉，现时不支持要求送至指定位置，我们的司机将会放置在安全地点。': 'Sorry, requesting delivery to a designated location is not supported currently. Our driver will place it in a safe place.',
  '我收到了一个不属于自己的包裹，我应该怎么办？': 'I received a parcel that I didn\'t order. What should I do?',
  '感谢您的告知，很抱歉给您带来不便。您的反馈对我们至关重要，请将Tracking no、您的联系方式、错送地址信息电邮至我们的邮箱customer_service@fasthorse.com.au，以便我们进一步跟进。': 'Thank you for your notice and sorry for the inconvenience. Your feedback is very important to us. Please email tracking no, your contact information, and your address information to our email customer_service@fasthorse.com.au so that we can follow up further.',
  '我想知道寄件方/我想查询订单详情？': 'I want to know who is the sender/ I want to know about the order details.',
  '很抱歉，FHE专注尾程配送，我们只负责送达包裹。关于包裹详情请及时联系商家处理。': 'Sorry, FHE focuses on last-mile delivery. We are only responsible for delivering parcels. If there is any problem with the parcel, please contact the sender for further assistance.',
  '常见问题': 'Q&A',
  '其他问题': 'Other questions',
  '此答案是否解决您的问题': 'Does this answer solve your problem?',
  '已解决': 'Resolved',
  '未解决': 'Unresolved',
  '${orderNo}配送信息如下': '${orderNo} delivery status:',
  '未查询到${orderNo}订单信息': '${orderNo} information not found.',
  '物流查询': 'Track your parcel',
  '查询': 'Track',
  '请输入运单号查询': 'Enter your tracking number',
  '查看签收情况': 'Check receipt status',
  '请输入寄件人或收件人手机号后4位完成验证': 'Please enter the last 4 digits of the sender or recipient\'s phone number to complete verification.',
  '输入不正确，请重新输入': 'Invalid input, please re-enter',
  '包裹放在': 'Put the parcel at',
  '配送情况': 'Delivery status',
  '包裹交给': 'Give the parcel to',
  '签收人': 'recipient',
  '收件人或同住人': 'Recipient or roommate',
  '前门': 'Front door',
  '后门': 'Rear door',
  '前台或门卫': 'Receptionist or doorman',
  '信箱': 'Mailbox',
  '快递柜': 'express cabinet',
  '其他位置': 'Another safe location',
  '取件码': 'Pickup code',
  '联系我们': 'Contact us',
  '请您输入收件人的姓查看签收信息': 'Please enter the recipient\'s last name to view the signing information.',
  '请输入': 'Please enter',
  '首页': 'Home Page',
  '向着你的': 'DRIVE TOWARDS',
  '理想生活前进': 'YOUR IDEAL LIFE',
  '今天就开始驾驶和赚钱': 'Start driving and earning today',
  '下载 FastHorse Express APP': 'Download the FastHorse Express APP',
  '使用自己的汽车，在空闲时间投送小件包裹': 'Use your own car to deliver small parcels',
  '每小时收入可达$30澳元': 'in spare time, earn up to $30 per hour',
  '上手简单': 'EASY TO USE',
  '简洁而完善的在线培训系统，15分钟掌握所有工作内容；': 'Our simple and complete online training system will help you master your work within 15 minutes;',
  '工作轻松': 'EASY TO WORK',
  '免费智能路线规划系统，辅助您用最短路程完成包裹投递；': 'Our smart route planning system will help you plan the shortest delivery route, which is FREE;',
  '时间灵活': 'FLEXIBLE HOURS',
  'Individual contractor合作模式，工作时间自主安排；': 'Our individual contractor model lets you schedule your own working hours;',
  '收入保障': 'GUARANTEED SETTLEMENT',
  '收入记录清晰透明，每周自动结算打款。': 'Our settlement system will show you clear and transparent earning records, and settle the payment automatically.',
  '加入这份事业，您需要具备：': 'Become a FastHorse Express driver, you need to have:',
  'Homexpress加入这份事业，您需要具备：': 'Become a Homexpress driver, you need to have:',
  '1. 年满20周岁及以上': '1. Over 20 years old (inclusive)',
  '2. 居住在墨尔本（这是FHE已开通服务的城市）': '2. Living near FHE’s service city',
  '2. 这是Homexpress已开通服务的城市）': '2. Living near Homexpress’s service city',
  '3. 拥有一辆符合条件的车': '3. Have a qualified car',
  '汽车、SUV、小型货车或货车任一种都可以。': 'There are three vehicle types that can be used to deliver FastHorse Express parcels in Australia: Sedans, Large Passenger Vehicles, Cargo Vans.',
  '不同车型关系到一次可装包裹的数量，根据我们的经验，一般四门car一次可装载4h的包裹，SUV大约6.5h，无后座的van可装8h的包裹。': 'For a single trip, sedans can deliver a 4-hour block, large passenger vehicles are ready for a 6.5-hour block and vans can deliver up to an 8-hour block (inclusive of a 30 minute unpaid break).',
  '4. 有完整且无限制的、剩余有效期超过3个月的澳大利亚驾照': '4. Hold a valid and unrestricted local driver\'s license with more than 3 months remaining validity',
  '5. 完善的车辆保险': '5. Have full vehicle insurance',
  '所需的车险除了对第三方人身伤害的强制险外，您还须包括第三方财产损害的保险': 'In addition to the mandatory third-party personal injury insurance, you must also insure third-party property damage insurance.',
  '6. 拥有澳大利亚企业编号 (ABN)。': '6. Have proof of working (SSN, ABN, NZBN, etc.).',
  '7. 通过背景调查确认。': '7. Pass the background check.',
  '今天就': 'Start driving and',
  '开始驾驶和赚钱': 'earning today',
  '手机扫描二维码下载 FastHorse Express App': 'SCAN QR-CODE to download the FastHorse Express APP on your mobile device',
  '选择语言': 'Select language',
  '国家或地区': 'Country or region',
  '澳大利亚': 'Australia',
  '美国': 'United States',
  '新西兰': 'New Zealand',
  '加拿大': 'Canada',
  '欢迎使用PDA系统': 'Welcome',
  '使用飞书扫描二维码登录': 'Scan with lark',
  '请输入账号': 'username',
  '请输入密码': 'password',
  '账号不能为空': 'username can not be empty',
  '密码不能为空': 'password can not be empty',
  '使用账号密码登录': 'Use account password to log in',
  '登录': 'Login',
  '请输入运单号': 'Enter your tracking number',
  '在线客服': 'online customer service',
  '待揽收': 'Ready for processing',
  '包裹异常': 'Parcel anomaly',
  '您可能遇到的问题': 'Problems you may encounter',
  '什么时候能送达': 'When will it be delivered',
  '修改收件地址': 'Modify the shipping address',
  '添加配送备注': 'Add remarks',
  '已添加备注': 'Remarks added',
  '添加备注后，我们会提醒司机查看。但如果司机正在配送中可能无法及时查看到备注信息。': 'Remarks have been added and we will remind the driver to check. However, the driver may not be able to view your remarks in time during the driving process. If the driver fails to view your remarks in time, please understand and thank you for your support.',
  '${finishTime}在${receivedType}签收': 'Signed by ${receivedType} at ${finishTime}',
  '我们暂未收到包裹，一般会在收到包裹之后3天完成配送': 'We haven\'t collected the parcel yet.Generally, distribution will be completed within 3 days of collection.',
  '请输入您的问题': 'Please enter your question',
  '验证身份信息失败，请重新输入': 'Failed to verify the identity information, please re-enter',
  '请输入收件人的姓氏': 'Please enter the recipient\'s last name',
  '您的包裹异常，我们正在处理': 'Your parcel is abnormal and we are processing it',
  '请输入正确的邮箱': 'Please enter the correct email address',
  '提交失败，请重试': 'Failed to submit, please try again',
  '提交成功': 'Submit successfully',
  '请输入您的电子邮件地址': 'Please enter your email address',
  '我们将与您沟通结果': 'We will communicate the results to you',
  '已添加的备注': 'Remarks have been added',
  '添加失败，请重试': 'Failed to add, please try again',
  '会话已超时，可返回上一页重新进入': 'The session has timed out. You can return to the previous page to re-enter',
  '预计今天送达': 'Expected delivery today',
  '未收到包裹': 'Parcel not received',
  '请输入备注': 'Please enter remarks',
  '为进一步协助我们调查此问题，以及确保解决问题，我们善意地请求您向我们提供以下信息': 'To assist us in further investigating this issue and ensuring a resolution, we kindly request that you provide us with the following information',
  '房屋或邮箱照片': 'Photos of houses with house numbers',
  '请上传房屋或邮箱照片': 'Please upload a photo of your house or email address',
  '图片上传大小不要超过10MB,请重新上传！': 'The image upload size should not exceed 10MB, please re-upload!',
  '签收图片不是我家': 'The delivery photo is not taken in my address',
  '签收图片是我家但是找不到包裹': 'The delivery photo is taken in my address but I cannot be found it',
  '其他': 'Others',
  '邮箱': 'mailbox',
  '欢迎加入FastHorse': 'Welcome to FastHorse',
  '手机号/电子邮箱': 'Phone number/email address',
  '手机号码或电子邮箱': 'Cell phone number or email address',
  '请输入你的账号': 'Please enter your account number',
  '请输入登录密码': 'Please enter your login password',
  '请输入你的密码': 'Please enter your password',
  '找回密码': 'Retrieve password',
  '密码必须是8-16位英文字母、数字、符号组合': 'The password must be a combination of 8-16 letters, digits, and symbols',
  '密码设置成功': 'Password set successfully',
  '忘记密码': 'Forgot password',
  '请输入您的手机号码': 'Please enter your mobile number',
  '手机号码': 'Mobile phone number',
  '短信验证码已通过短信已发送至': 'SMS verification code has been sent via SMS to',
  '如果你没有收到验证码，请检查垃圾信息': 'If you do not receive a verification code, please check for spam',
  '重新发送验证码': 'Resend the verification code',
  '获取验证码': 'Get verification code',
  '设置登录密码': 'Set login password',
  '请输入新密码': 'Please enter your new password',
  '下一步': 'next',
  '完成': 'Done',
  '未结算数据': 'Unsettled data',
  '历史账单': 'Historical statement',
  '司机数据': 'Driver data',
  '退出登录': 'Log out',
  '刷新': 'Refresh',
  '历史数据': 'Historical data',
  '合计金额': 'Total amount',
  '佣金': 'Commission',
  '配送费': 'Delivery fee',
  '补贴': 'subsidy',
  '扣款': 'Deduction',
  '配送数据': 'Delivery data',
  '个包裹': 'Parcels',
  '箱金额': 'Total',
  '待接单': 'Awaiting acceptance',
  '待取货': 'Waiting for pickup',
  '配送中': 'In delivery',
  '待返仓': 'Waiting for return',
  '等待接单': 'Wait to be accepted',
  '派单时间': 'Dispatched',
  '等待取货': 'Waiting for pickup',
  '计划取货时间': 'Planned pickup',
  '取货已迟到': 'Be late',
  '个包裹剩余': 'left',
  '计划完成时间': 'Planned completion',
  '配送已完成': 'Delivered',
  '完成配送时间': 'Completion',
  '${num}个异常包裹未返仓': '${num} parcels  to be returned',
  '暂无数据': 'No data available',
  '${num}个包裹': '${num} parcels',
  '详情': 'details',
  '最少保留一个': 'Keep at least one',
  '完成箱数': 'Delivered boxes',
  '完成包裹数': 'Delivered parcels',
  '数据趋势': 'Data trends',
  '数据明细': 'Data Details',
  '(${num}条)': '(${num} pieces)',
  '序号': 'SN',
  '日期': 'Date',
  '司机姓名': 'Driver\'s name',
  '电子邮箱': 'Email address',
  '结算状态': 'Settlement status',
  '未结算': 'unsettled',
  '已结算': 'settled',
  '查看明细': 'View details',
  '税': 'tax',
  '注册日期': 'Registration Date',
  '所有司机': 'All drivers',
  '开始日期': 'Start date',
  '结束日期': 'End date',
  '请选择结束日期': 'Please select an end date',
  '司机统计': 'Driver statistics',
  '注册司机': 'Registered driver',
  '审核通过司机': 'Approved driver',
  '最近7天活跃司机': 'Active driver in the last 7 days',
  '封禁司机': 'Banned driver',
  '司机明细': 'Driver details',
  '(${num}个)': '(${num})',
  '账号状态': 'Account status',
  '可配送周期': 'Deliverable time period',
  '每日工作时长': 'Daily working hours',
  '到底了~': 'At the bottom~',
  '小时': 'Hour (s)',
  '您的交货情况如何?': 'How was your delivery?',
  '匿名': 'anonymous',
  '不满意': 'dissatisfy',
  '还行': 'normal',
  '满意': 'satisfaction',
  '已完成评价': 'Review completed',
  '已超出可评价期限': 'The evaluation period has expired',
  '非常抱歉，我们很重视你的反馈， 你的反馈将对司机的收入造成影响，我们将会继续提升服务质量': 'We\'re very sorry. We attach great importance to your feedback. Your feedback will affect the driver\'s income. We will continue to improve the service quality.',
  '感谢你的反馈，我们将继续提升服务质量': 'Thanks for your feedback,We will continue to improve service quality',
  '知道了': 'Got it',
  '需在签收后 7 天内进行评价': 'Evaluation is required within 7 days of receipt',
  '匿名评价': 'Anonymous review',
  '请详细描述你遇到的问题': 'Please describe the problem you encountered in detail',
  '期望得到你的建议': 'Looking forward to getting your advice',
  '不满意，我要吐槽': 'Not satisfied, I want to complain',
  '还行，这些方面还能做的更好': 'Not bad, we can do better in these aspects',
  '服务太棒了，我想夸夸配送员': 'The service is great, I want to praise the delivery person',
  '请选择或填写不满意的原因': 'Please select or fill in the reason for dissatisfaction',
  '欢迎加入Homexpress': 'Welcome to join Homexpress',
  '下载 Homexpress APP': 'Download Homexpress APP',
  '手机扫描二维码下载 Homexpress APP': 'Scan the QR code to download Homexpress APP',
};
