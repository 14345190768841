export default {
  '订单查询': 'Localiza Pedido',
  '帮助？': '¿Necesita Ayuda?',
  '寄件地': 'Lugar de envío',
  '预计送达': 'Entrega estimada',
  '快递单号': 'Número de Pedido',
  '您的包裹': 'Tu pedido',
  '完整路径': 'Estado de seguimiento',
  '更多': 'Ver más',
  '查询其他包裹': 'Seguimiento de otro paquete',
  '已送达': 'Envío entregado',
  '${realReceiverName} 已签收': 'Firmado',
  '配送评价': '¿Cómo fue su parto?',
  '允许我们与您取得联系？': 'Permítanos ponernos en contacto con usted',
  '同意': 'Sí',
  '不同意': 'No',
  '备注': 'Nota',
  '添加备注': 'Añadir una nota',
  '修改备注': 'Modificar una observación',
  '如果包裹当前正在配送中，司机可能看不到，暂不支持通过备注修改地址': 'Si el paquete se está distribuyendo actualmente, el conductor puede no verlo y cambiar la dirección a través de la observación no es compatible por el momento',
  '如果您有需要提醒司机的事宜,可以通过备注告诉他': 'Si hay algo que necesita alertar al conductor, puede decirle a través de la nota',
  '确定': 'determinar',
  '取消': 'cancelación',
  '系统显示我的包裹“已丢失”，我应该怎么办？': 'El sistema indica que mi paquete está "perdido". ¿Qué debo hacer?',
  '很抱歉，我们会尽快联系卖家进行赔偿。请联络卖家处理。': 'Lo sentimos, nos pondremos en contacto con el vendedor para la compensación tan pronto como sea posible. Póngase en contacto con el vendedor para el procesamiento.',
  '系统显示我的包裹“已签收”，但是我没找到，我应该怎么办？': 'El sistema dice que mi paquete "firmado", pero no lo encuentro. ¿Qué debo hacer?',
  '请参考签收图片，并询问邻居或大厦管理员。如果仍未找到，建议联络警方处理。': 'Por favor, consulte la imagen de la firma y pregunte a los vecinos o al administrador del edificio. Si aún no se encuentra, se recomienda ponerse en contacto con la policía.',
  '系统显示我的包裹“已签收”，但是签收图片不是我家，我应该怎么办？': 'El sistema dice que mi paquete está "firmado", pero la imagen de la firma no es mi casa. ¿Qué debo hacer?',
  '很抱歉，请将Tracking no、系统签收时间、正确地址信息（附正确地址照片）、包裹价值（附订单截图）电邮至我们的邮箱customer_service@fasthorse.com.au，以便我们进一步跟进。': 'Lo sentimos, por favor envíe un correo electrónico a nuestro correo electrónico customer_service@fasthorse.com.au con el seguimiento no, la hora de la firma del sistema, la información de la dirección correcta (con una foto de la dirección correcta) y el valor del paquete (con una captura de pantalla del pedido) para un seguimiento adicional.',
  '我买的东西少件了/这不是我买的东西，我应该怎么办？': 'Faltan piezas en mi compra/no es mi compra, ¿Qué debo hacer?',
  '很抱歉，FHE专注尾程配送，我们只负责送达包裹。包裹内容物有问题请及时联系商家处理。': 'Lo sentimos, pero FHE se centra en la entrega de la cola, sólo somos responsables de la entrega del paquete. Cualquier problema con el contenido del paquete, póngase en contacto con el comerciante a tiempo para procesar.',
  '我的包裹破损了，内容物已经损坏，我应该怎么办？': 'Mi paquete está roto y su contenido está dañado. ¿Qué debo hacer?',
  '请参考签收图片。若签收时外包装破损，请将Tracking no、系统签收时间、包裹照片、包裹价值（附订单截图）、其他有用资料（如有）电邮至我们的邮箱customer_service@fasthorse.com.au，以便我们进一步跟进。': 'Refiera por favor la imagen de la firma. Si el paquete externo está roto al firmar, envíe un correo electrónico a nuestro correo electrónico customer_service@fasthorse.com.au con el seguimiento no, el tiempo de firma del sistema, la foto del paquete, el valor del paquete (con una captura de pantalla del pedido), otra información útil (si está disponible) para que podamos seguir más.',
  '系统显示我的包裹“已为您创建订单”，我何时才能收到？': 'El sistema dice que mi paquete "se ha creado un pedido para usted". ¿Cuándo lo recibiré?',
  '请耐心等待，包裹尚未到达仓库，入库后我们将尽快安排派送。': 'Por favor, sea paciente, el paquete no ha llegado al almacén, después de almacenarlo, arreglaremos la entrega cuanto antes.',
  '系统显示我的包裹“待配送”，我何时才能收到？': 'El sistema indica que mi paquete está "pendiente de entrega". ¿Cuándo lo recibiré?',
  '请耐心等待，我们将尽快安排派送。': 'Por favor, sea paciente, organizaremos la entrega lo antes posible.',
  '系统显示我的包裹“派送中”，我何时才能收到？': 'El sistema indica que mi paquete está "en envío". ¿Cuándo lo recibiré?',
  '请耐心等待，通常情况下司机将于24小时内送达，实际送达时间视情况而定。': 'Por favor, sea paciente. Por lo general, el conductor entregará dentro de las 24 horas. El tiempo real de entrega depende de la situación.',
  '系统显示我的包裹“派送失败返仓中”，我何时才能收到？': 'El sistema indica que mi paquete "falló en la entrega de devolución". ¿Cuándo lo recibiré?',
  '请耐心等待，我们会尽快安排第二次派送，或将包裹退回至卖家。请关注物流信息。': 'Por favor, sea paciente. Arreglaremos la segunda entrega lo antes posible o devolveremos el paquete al vendedor. Preste atención a la información logística.',
  '系统显示我的包裹“已退运”，我何时才能收到？': 'El sistema indica que mi paquete ha sido devuelto. ¿Cuándo lo recibiré?',
  '很抱歉，我们已将包裹退回卖家，请联络卖家处理。': 'Lo sentimos, pero hemos devuelto el paquete al vendedor. Póngase en contacto con el vendedor para procesar.',
  '系统显示我的包裹“已转运”，我何时才能收到？': 'El sistema indica que mi paquete está "en tránsito". ¿Cuándo lo recibiré?',
  '请耐心等待，我们已将包裹转至其他公司配送。您仍可在我们的网站追踪物流。': 'Por favor, sea paciente, hemos transferido el paquete a otra empresa para su distribución. Usted puede seguir la logística en nuestro sitio web.',
  '为什么会显示“导航/地址错误”？': '¿Por qué aparece "error de navegación/dirección"?',
  '很抱歉，可能是由于系统导航无法正确定位地址，我们将尽快安排转至其他公司配送或退回卖家。': 'Lo sentimos, pero puede ser debido a que la navegación del sistema no puede localizar correctamente la dirección. Organizaremos la transferencia a otra empresa para la entrega o la devolución al vendedor lo antes posible.',
  '我是否能到仓库自提？': '¿Puedo recogerlo en el almacén?',
  '抱歉，现时不支持仓库自取。请耐心等待，我们将尽快将包裹送达。': 'Lo sentimos, pero en este momento no es compatible con el almacén. Por favor, sea paciente, entregaremos el paquete lo antes posible.',
  '我是否可以选择送达时间？': '¿Puedo elegir el tiempo de entrega?',
  '抱歉，现时不支持选择送达时间。请耐心等待，我们将尽快将包裹送达。': 'Lo sentimos, la selección del tiempo de entrega no es compatible en este momento. Por favor, sea paciente, entregaremos el paquete lo antes posible.',
  '我是否可以修改收货地址？': '¿Puedo modificar mi dirección de envío?',
  '抱歉，现时不支持修改送货地址。如有相关需求请联系卖家处理。': 'Lo sentimos, cambiar la dirección de envío no es compatible en este momento. Póngase en contacto con el vendedor para manejar si tiene necesidades relacionadas.',
  '我是否可以要求司机送至指定位置？': '¿Puedo pedir al conductor que me lleve a la ubicación indicada?',
  '抱歉，现时不支持要求送至指定位置，我们的司机将会放置在安全地点。': 'Lo sentimos, las solicitudes de traslado a la ubicación especificada no son compatibles en este momento. Nuestro conductor lo colocará en un lugar seguro.',
  '我收到了一个不属于自己的包裹，我应该怎么办？': 'He recibido un paquete que no es mío. ¿Qué debo hacer?',
  '感谢您的告知，很抱歉给您带来不便。您的反馈对我们至关重要，请将Tracking no、您的联系方式、错送地址信息电邮至我们的邮箱customer_service@fasthorse.com.au，以便我们进一步跟进。': 'Gracias por informarnos y disculpen las molestias. Sus comentarios son muy importantes para nosotros. Por favor, envíenos un correo electrónico a customer_service@fasthorse.com.au con el seguimiento no, su información de contacto y la información de la dirección de envío incorrecta para un seguimiento adicional.',
  '我想知道寄件方/我想查询订单详情？': '¿Me gustaría conocer el remitente/me gustaría consultar los detalles del pedido?',
  '很抱歉，FHE专注尾程配送，我们只负责送达包裹。关于包裹详情请及时联系商家处理。': 'Lo sentimos, pero FHE se centra en la entrega de la cola, sólo somos responsables de la entrega del paquete. Sobre los detalles del paquete, póngase en contacto con el comerciante a tiempo para procesar.',
  '常见问题': 'PREGUNTAS FRECUENTES',
  '其他问题': 'Para otras preguntas',
  '此答案是否解决您的问题': 'Si esta respuesta resuelve su problema',
  '已解决': 'resuelto',
  '未解决': 'Sin resolver',
  '${orderNo}配送信息如下': 'La información de entrega de ${orderNo} es la siguiente',
  '未查询到${orderNo}订单信息': '${orderNo} información no encontrada.',
  '物流查询': 'Seguimiento de su paquete',
  '查询': 'Seguimiento',
  '请输入运单号查询': 'Introduzca el número de seguimiento para consultar',
  '查看签收情况': 'Verifique el estado del paquete',
  '请输入寄件人或收件人手机号后4位完成验证': 'Ingrese los últimos 4 dígitos del número de teléfono del remitente o del destinatario para completar la verificación.',
  '输入不正确，请重新输入': 'Dato incorrecto, vuelva a intentar',
  '包裹放在': 'Deja el paquete en',
  '配送情况': 'Estado de la entrega',
  '包裹交给': 'Entrgar el paquete a',
  '签收人': 'Firmante',
  '收件人或同住人': 'Destinatario o compañero de cuarto',
  '前门': 'Puerta Principal',
  '后门': 'Puerta trasera',
  '前台或门卫': 'Recepción o portero',
  '信箱': 'Correo',
  '快递柜': 'Gabinete de almacenamiento express',
  '其他位置': 'otro sitio',
  '取件码': 'Código de recogida',
  '联系我们': 'Contactar',
  '请您输入收件人的姓查看签收信息': 'Por favor, introduzca el apellido del destinatario para ver la información de la firma.',
  '请输入': 'Por favor ingrese',
  '首页': 'página principal',
  '向着你的': 'hacia ti',
  '理想生活前进': 'Tu vida ideal',
  '今天就开始驾驶和赚钱': 'Comience a conducir y a ganar dinero hoy',
  '下载 FastHorse Express APP': 'Descarga la aplicación FastHorse Express',
  '使用自己的汽车，在空闲时间投送小件包裹': 'Use su propio vehículos para dejar paquetes pequeños en su tiempo libre',
  '每小时收入可达$30澳元': 'Gana hasta 30€ por hora',
  '上手简单': 'Fácil de usar',
  '简洁而完善的在线培训系统，15分钟掌握所有工作内容；': 'Sistema de capacitación en línea simple y perfecto, domina todo el contenido del trabajo en 15 minutos;',
  '工作轻松': 'Trabajo fácil',
  '免费智能路线规划系统，辅助您用最短路程完成包裹投递；': 'Sistema de planificación de ruta inteligente gratuito para ayudarlo a completar la entrega del paquete con la distancia más corta;',
  '时间灵活': 'tiempo flexible',
  'Individual contractor合作模式，工作时间自主安排；': 'Nuestro modelo de contratista individual le permite programar su propio horario de trabajo;',
  '收入保障': 'Ingreso Garantizada',
  '收入记录清晰透明，每周自动结算打款。': 'El registro de ingresos es claro y transparente, y el pago se liquida y paga automáticamente cada semana.',
  '加入这份事业，您需要具备：': 'Para trabajar con nosotros, debe tener:',
  'Homexpress加入这份事业，您需要具备：': 'Para trabajar con nosotros, debe tener:',
  '1. 年满20周岁及以上': '1. Al menos 20 años o más',
  '2. 居住在墨尔本（这是FHE已开通服务的城市）': '2. Vivir cerca de la ciudad de servicio de FHE',
  '2. 这是Homexpress已开通服务的城市）': '2. Vivir cerca de la ciudad de servicio de Homexpress',
  '3. 拥有一辆符合条件的车': '3. Tiene propio vehículos',
  '汽车、SUV、小型货车或货车任一种都可以。': 'Cualquier tipo de vehículos, como coche, SUV, frogoneta o camión.',
  '不同车型关系到一次可装包裹的数量，根据我们的经验，一般四门car一次可装载4h的包裹，SUV大约6.5h，无后座的van可装8h的包裹。': 'Los diferentes vehículos tienen la diferencia  con la cantidad de paquetes que se pueden cargar a la vez, según nuestra experiencia, un automóvil general de cuatro puertas puede cargar 4 horas de paquetes a la vez, un SUV es de aproximadamente 6.5 horas y una camioneta sin un el asiento trasero puede contener 8 horas de paquetes.',
  '4. 有完整且无限制的、剩余有效期超过3个月的澳大利亚驾照': '4. Tener una licencia de conducir local válida y sin restricciones con más de 3 meses de validez restante',
  '5. 完善的车辆保险': '5. Tiene seguro de vehículo a todo riesgo',
  '所需的车险除了对第三方人身伤害的强制险外，您还须包括第三方财产损害的保险': 'Seguro de coche obligatorio Además de la cobertura obligatoria por lesiones corporales a terceros, también debe incluir un seguro por daños a la propiedad de terceros',
  '6. 拥有澳大利亚企业编号 (ABN)。': '6. Tener prueba de trabajo (SSN, ABN, NZBN, etc.).',
  '7. 通过背景调查确认。': 'verificación de antecedentes',
  '今天就': 'Empezamos hoy',
  '开始驾驶和赚钱': 'empieza a conducir y gana dinero',
  '手机扫描二维码下载 FastHorse Express App': 'Escanee el código QR con su teléfono para descargar la aplicación FastHorse Express',
  '选择语言': 'Seleccione su idioma',
  '国家或地区': 'País o región',
  '澳大利亚': 'australiano',
  '美国': 'Estados Unidos',
  '新西兰': 'neozelandés',
  '加拿大': 'Canadá',
  '欢迎使用PDA系统': 'Bienvenido',
  '使用飞书扫描二维码登录': 'Escanear con alondra',
  '请输入账号': 'cuenta',
  '请输入密码': 'contraseña',
  '账号不能为空': 'La cuenta no puede estar vacía',
  '密码不能为空': 'La contraseña no puede estar vacía',
  '使用账号密码登录': 'Iniciar sesión con contraseña de cuenta',
  '登录': 'Iniciar sesión',
  '请输入运单号': 'Por favor ingrese el número de seguimiento',
  '在线客服': 'Atención al cliente online',
  '待揽收': 'Listo para el procesamiento',
  '包裹异常': 'Paquete excepcional',
  '您可能遇到的问题': 'Problemas que puede encontrar',
  '什么时候能送达': 'Cuando se entregará',
  '修改收件地址': 'Modificar dirección de entrega',
  '添加配送备注': 'Añadir observaciones de entrega',
  '已添加备注': 'Se ha añadido una observación',
  '添加备注后，我们会提醒司机查看。但如果司机正在配送中可能无法及时查看到备注信息。': 'Después de agregar una nota, le recordaremos al conductor que la revise. Pero si el conductor está en la entrega puede no ser capaz de ver la información de observación a tiempo.',
  '${finishTime}在${receivedType}签收': '${finishTime} firmado en ${receivedType}',
  '我们暂未收到包裹，一般会在收到包裹之后3天完成配送': 'No hemos recibido el paquete por el momento, generalmente terminaremos la entrega 3 días después de recibir el paquete',
  '请输入您的问题': 'Por favor ingrese su pregunta',
  '验证身份信息失败，请重新输入': 'La verificación de la información de identidad falló. Por favor, vuelva a introducirla',
  '请输入收件人的姓氏': 'Por favor, introduzca el apellido del destinatario',
  '您的包裹异常，我们正在处理': 'Anomalías en su paquete, estamos manejando',
  '请输入正确的邮箱': 'Por favor, introduzca un correo electrónico correcto',
  '提交失败，请重试': 'El envío falló, inténtelo de nuevo',
  '提交成功': 'Enviado con éxito',
  '请输入您的电子邮件地址': 'Por favor, introduzca su dirección de correo electrónico',
  '我们将与您沟通结果': 'Te comunicaremos los resultados',
  '已添加的备注': 'Observaciones que se han añadido',
  '添加失败，请重试': 'Error al agregar, inténtelo de nuevo',
  '会话已超时，可返回上一页重新进入': 'La sesión ha expirado, puede volver a la página anterior',
  '预计今天送达': 'Entrega prevista hoy',
  '未收到包裹': 'Paquete no recibido',
  '请输入备注': 'Por favor ingrese una observación',
  '为进一步协助我们调查此问题，以及确保解决问题，我们善意地请求您向我们提供以下信息': 'Para ayudarnos aún más en la investigación de este problema, así como para garantizar que se resuelva el problema, le pedimos amablemente que nos proporcione la siguiente información',
  '房屋或邮箱照片': 'Fotos de casa o buzón',
  '请上传房屋或邮箱照片': 'Por favor, sube una foto de la casa o buzón',
  '图片上传大小不要超过10MB,请重新上传！': 'El tamaño de la imagen no debe superar los 10MB. ¡Vuelva a cargarla!',
  '签收图片不是我家': 'Imagen firmada no mi casa',
  '签收图片是我家但是找不到包裹': 'La imagen de la firma es mi casa, pero no puedo encontrar el paquete',
  '其他': 'otros',
  '邮箱': 'dirección',
  '欢迎加入FastHorse': 'Bienvenido a FastHorse',
  '手机号/电子邮箱': 'Número de teléfono/correo electrónico',
  '手机号码或电子邮箱': 'Número de teléfono móvil o correo electrónico',
  '请输入你的账号': 'Por favor ingrese su cuenta',
  '请输入登录密码': 'Por favor, introduzca su contraseña',
  '请输入你的密码': 'Por favor, introduzca su contraseña',
  '找回密码': 'Recupera tu contraseña',
  '密码必须是8-16位英文字母、数字、符号组合': 'La contraseña debe ser una combinación de 8-16 caracteres de letras, números y símbolos en inglés',
  '密码设置成功': 'Contraseña establecida con éxito',
  '忘记密码': 'Olvidar la contraseña',
  '请输入您的手机号码': 'Por favor ingrese su número de móvil',
  '手机号码': 'Teléfono móvil',
  '短信验证码已通过短信已发送至': 'SMS el código de verificación ha sido enviado por SMS a',
  '如果你没有收到验证码，请检查垃圾信息': 'Si no ha recibido el código de verificación, por favor revise los mensajes de spam',
  '重新发送验证码': 'Reenviar captcha',
  '获取验证码': 'Obtener el captcha',
  '设置登录密码': 'Establecer contraseña de acceso',
  '请输入新密码': 'Introduzca una nueva contraseña',
  '下一步': 'Siguiente paso',
  '完成': 'Hecho',
  '未结算数据': 'Datos sin liquidar',
  '历史账单': 'Factura histórica',
  '司机数据': 'Datos del conductor',
  '退出登录': 'Salir del login',
  '刷新': 'Una actualización',
  '历史数据': 'Datos históricos',
  '合计金额': 'Cantidad total',
  '佣金': 'La comisión',
  '配送费': 'Gastos de entrega',
  '补贴': 'Las subvenciones',
  '扣款': 'devoluciones',
  '配送数据': 'Datos de distribución',
  '个包裹': 'Paquetes',
  '箱金额': 'Total',
  '待接单': 'Esperando ser aceptado',
  '待取货': 'Esperando la recogida.',
  '配送中': 'En entrega',
  '待返仓': 'En espera de devolución',
  '等待接单': 'Esperando ser recogido',
  '派单时间': 'Enviado',
  '等待取货': 'Esperando la recogida.',
  '计划取货时间': 'Planifique su recogida',
  '取货已迟到': 'Llegó tarde',
  '个包裹剩余': 'El resto',
  '计划完成时间': 'Finalización del plan',
  '配送已完成': 'Entregado',
  '完成配送时间': 'La terminación',
  '${num}个异常包裹未返仓': '${num} paquetes a devolver',
  '暂无数据': 'Datos aún no disponibles',
  '${num}个包裹': '${num} parcelas',
  '详情': 'quienes',
  '最少保留一个': 'Reserva mínima de uno',
  '完成箱数': 'Cajas entregadas',
  '完成包裹数': 'Paquetes entregados',
  '数据趋势': 'Tendencias en datos',
  '数据明细': 'Detalle de datos',
  '(${num}条)': '(${num} piezas)',
  '序号': 'El número',
  '日期': 'Fecha',
  '司机姓名': 'Nombre del conductor',
  '电子邮箱': 'Correo electrónico',
  '结算状态': 'Estado de liquidación',
  '未结算': 'Sin resolver',
  '已结算': 'liquidado',
  '查看明细': 'Ver los detalles',
  '税': 'impuestos',
  '注册日期': 'Fecha de inscripción',
  '所有司机': 'Todos los conductores',
  '开始日期': 'Fecha de inicio',
  '结束日期': 'Fecha de finalización',
  '请选择结束日期': 'Por favor seleccione una fecha de finalización',
  '司机统计': 'Estadísticas para conductores',
  '注册司机': 'Conductor registrado',
  '审核通过司机': 'Conductores examinados',
  '最近7天活跃司机': 'Conductores activos los últimos 7 días',
  '封禁司机': 'Conductor prohibido',
  '司机明细': 'Datos del conductor',
  '(${num}个)': '(${num})',
  '账号状态': 'Estado de cuenta',
  '可配送周期': 'Plazo de entrega',
  '每日工作时长': 'Duración del trabajo diario',
  '到底了~': 'Hasta el fondo~',
  '小时': 'horas',
  '您的交货情况如何?': '¿Cómo va tu entrega?',
  '匿名': 'anónimo',
  '不满意': 'No satisfecho',
  '还行': 'bien',
  '满意': 'satisfacer',
  '已完成评价': 'Revisión completada',
  '已超出可评价期限': 'El período de evaluación ha expirado.',
  '非常抱歉，我们很重视你的反馈， 你的反馈将对司机的收入造成影响，我们将会继续提升服务质量': 'Lo sentimos mucho. Damos gran importancia a sus comentarios. Sus comentarios afectarán los ingresos del conductor. Continuaremos mejorando la calidad del servicio.',
  '感谢你的反馈，我们将继续提升服务质量': 'Gracias por tus comentarios,Seguiremos mejorando la calidad del servicio.',
  '知道了': 'sabía',
  '需在签收后 7 天内进行评价': 'Se requiere evaluación dentro de los 7 días posteriores a la recepción',
  '匿名评价': 'Revisión anónima',
  '请详细描述你遇到的问题': 'Describa detalladamente el problema que encontró.',
  '期望得到你的建议': 'Espero recibir su consejo',
  '不满意，我要吐槽': 'No satisfecho, quiero quejarme.',
  '还行，这些方面还能做的更好': 'No está mal, podemos hacerlo mejor en estos aspectos.',
  '服务太棒了，我想夸夸配送员': 'El servicio es excelente, quiero elogiar al repartidor.',
  '请选择或填写不满意的原因': 'Por favor seleccione o complete el motivo de la insatisfacción',
  '欢迎加入Homexpress': 'Bienvenido a Homexpress',
  '下载 Homexpress APP': 'Descargar la aplicación Homexpress',
  '手机扫描二维码下载 Homexpress APP': 'Escanee el código QR para descargar la aplicación Homexpress',
};
